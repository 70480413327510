import React from "react";
import styled from "styled-components";
import PortableText from "@sanity/block-content-to-react";
import { urlForImageRef } from "./../../../lib/sanityClient";

import MyLink from "./myLink";

const StyledDiv = styled.div`
  ul {
    margin-left: 30px;
    margin-bottom: 25px;
  }

  a {
    text-decoration: underline;
    font-family: "bold";
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }

  ol {
    margin-left: 30px;
    margin-bottom: 25px;
    li {
      list-style: auto;
    }
  }

  p,
  li {
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 15px;
      line-height: 25px;
    }
  }

  strong {
    font-family: "bold" !important;
  }

  @media only screen and (max-width: 600px) {
    ul {
      margin-left: 20px;
    }
  }
`;

const Img = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 25px 0;
`;

const serializers = {
  types: {
    link: props => (
      <MyLink url={props.node.url} className="theme-btn block red">
        {props.node.linkText}
      </MyLink>
    ),
    inlinelink: props => (
      <MyLink url={props.node.url} className="theme-btn inline red">
        {props.node.linkText}
      </MyLink>
    ),
    image: props => {
      return (
        <div style={{ width: "100%", margin: "20px auto" }}>
          <Img src={urlForImageRef(props.node.asset).url() as string} />
        </div>
      );
    },
    socialLink: props => {
      if (props.node == null || props.node.socialLink == null) return null;

      return (
        <MyLink url={props.node.socialLink}>
          <Img src={urlForImageRef(props.node.socialIcon.asset).url() as string} />
        </MyLink>
      );
    },
  },
};

interface Blocks {
  blocks: any[];
}

const ContentBlock: React.FC<Blocks> = ({ blocks }) => {
  return (
    <StyledDiv className="rich-text">
      <PortableText
        blocks={blocks}
        serializers={serializers}
        imageOptions={{ auto: "format", fit: "fill" }}
        projectId={process.env.GATSBY_SANITY_PROJECT_ID}
        dataset={process.env.GATSBY_SANITY_DATASET}
      />
    </StyledDiv>
  );
};

export default ContentBlock;
